<template>
  <g :fill="color" fill-rule="nonzero" transform="translate(-8, -8) scale(0.05, 0.05)">
    <path
      :fill="color"
      d="M410.66,558.55c-90.78,0-164.63-73.85-164.63-164.63,0-66.5,39.62-126.1,100.93-151.85l22.1-9.28-12.1,20.69c-13.23,22.63-20.22,48.53-20.22,74.9,0,81.95,66.67,148.63,148.63,148.63,19.94,0,39.29-3.88,57.5-11.53l22.11-9.29-12.1,20.7c-14.26,24.39-34.68,44.84-59.06,59.14-25.12,14.73-53.88,22.52-83.16,22.52ZM332.38,267.52c-43.36,26.8-70.34,74.25-70.34,126.4,0,81.95,66.67,148.63,148.63,148.63,26.44,0,52.39-7.03,75.06-20.32,15.26-8.95,28.79-20.56,39.96-34.18-13.09,3.3-26.57,4.96-40.31,4.96-90.78,0-164.63-73.85-164.63-164.63,0-20.93,3.98-41.6,11.63-60.86Z"
    />
    <path
      :fill="color"
      d="M497.97,421.92l-49.84-26.2-49.84,26.2,9.52-55.5-40.32-39.31,55.73-8.1,24.92-50.5,24.92,50.5,55.73,8.1-40.32,39.31,9.52,55.5ZM448.13,377.64l28.59,15.03-5.46-31.84,23.13-22.55-31.97-4.65-14.3-28.97-14.3,28.97-31.97,4.65,23.13,22.55-5.46,31.84,28.59-15.03Z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
